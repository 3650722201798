import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Hr from '../components/Hr'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'
import Button from '../components/Button/Button'
import { 
  styledNamePage,
  header,
  content,
  callTo
} from '../components/styles/DedicatedManagement.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledNamePage}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
            mb={[50]}
          >
            <H4>
              <span>GERENCIAMENTO DEDICADO</span>
              Ideal para empresas que não têm uma equipe interna de TI
            </H4>
            <Paragraph>Monitoraremos a conectividade do seu servidor e prestaremos suporte a problemas de hardware. Reboots ilimitados.</Paragraph>
          </Column>
        </Row>
        <Row
          flexDirection={['column']}
          css={content}
        >
          <Column
            width={[1]}
            mb={[16]}
          >
            <H5>Gerenciamento Avançado</H5>
            <Paragraph>O Gerenciamento Avançado é ideal para empresas que procuram economizar tempo e recursos, e dedicar sua equipepara focar no que é relevante para o negócio.</Paragraph>
          </Column>
          <Column
            width={[1]}
          >
            <H5>Nosso Gerenciamento Avançado abrange os itens a seguir:</H5>
            <Paragraph>Ajuda nos backups e restaurações se necessário (apenas em servidores com 2 ou mais drives)</Paragraph>
            <Paragraph>Atualizações de WHM/cPanel</Paragraph>
            <Paragraph>Atualizações do PHP</Paragraph>
            <Paragraph>Atualizações do MySQL</Paragraph>
            <Paragraph>Atualizações de serviços de sistema pré-instalados. (não atualizamos scripts de terceiros instalados)</Paragraph>
            <Paragraph>A assistência para WHM/cPanel inclui, mas não limitado a, pré-configuração para funcionamento do serviço</Paragraph>
            <Paragraph>Compilação do PHP</Paragraph>
            <Paragraph>Otimização de MySQL</Paragraph>
            <Paragraph>Configuração e instalação do SSL</Paragraph>
            <Paragraph>Instalação do servidor DNS</Paragraph>
            <Paragraph>Teste de erros de E-mail</Paragraph>
            <Paragraph>Teste de erros de FTP</Paragraph>
            <Paragraph>Teste de erros de High Server Load</Paragraph>
            <Paragraph>Suporte a Best Effort Third Party Application</Paragraph>
            <Paragraph>Compromisso na Recuperação do Servidor</Paragraph>
            <Paragraph>Instalação e Configuração de Software Firewall</Paragraph>
            <Paragraph>Em caso de drive com falha, iremos assistir na restauração de todas as contas de nossa responsabilidade.</Paragraph>
            <Paragraph>Assistência em caso de corrupção na base de dados</Paragraph>
            <Paragraph>Assistência com alertas ou advertências de sistema</Paragraph>
            <Paragraph>Disponibilidade para ajudar com configurações de pré-instalação de sistema</Paragraph>
            <Paragraph>Necessidade de consultas de alto tráfego</Paragraph>
          </Column>
          <Column
            width={[1]}
          >
            <H5>Caso você precise de um gerenciamento customizado, fale com nossos consultores</H5>
            <div css={callTo}>
              <Button
                onClick={() => navigate('/contatos')}
                align='center'
                style={{
                  fontWeight: 600,
                  lineheight: 1.16,
                  letterSpacing: 1.25,
                  padding: '15px 16px 14px 16px',
                  width: 328,
                  color: '#ffffff',
                  outline: 'none',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                }}
              >
                FALAR COM UM CONSULTOR
              </Button>
            </div>
            <Hr
              lineColor='lightPeriwinkle'
              maxWidth={[328, '100%', 680, 680]}
              style={{
                opacity: 0.5,
                margin: '0 auto'
              }}
            />
          </Column>
          <Column
            width={[1]}
            mt={[40, 50]}
            mb={[16]}
          >
            <H5>Assistência e direcionamentos para atualizar servidor</H5>
            <Paragraph>O escopo do nosso Gerenciamento Avançado é limitado, mas, apesar disso, trabalharemos para lhe auxiliar da melhor maneira possível quanto às necessidades e atualizações do seu servidor. Conte com nosso Suporte Técnico especializado, disponível 24x7x365."</Paragraph>
          </Column>
          <Column
            width={[1]}
          >
            <H5>Importante</H5>
            <Paragraph>Ao aderir ao serviço de Gerenciamento Avançado, você deverá manter o mesmo ativo por um período mínimo de 3 meses. O custo do Gerenciamento Avançado é cobrado por servidor.</Paragraph>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Gerenciamento dedicado' />
      <PageTemplate
        title='Gerenciamento dedicado'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
