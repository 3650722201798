import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledNamePageBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const styledNamePage = css`
  ${styledNamePageBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 422, 504],
    marginLeft: [0, 0, 288, 390],
    marginRight: [0, 0, 288, 390],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const callTo = css(
  mq({
    marginLeft: [0, 0, 288, 390],
    marginRight: [0, 0, 464, 566],
    marginTop: [30],
    marginBottom: [49.5]
  })
)

export const lineBorder = css(
  mq({
    marginLeft: [0, 89, 288, 390],
    marginRight: [0, 89, 288, 390],
    borderBottom:['solid 1px rgba(217, 222, 227, 0.5)'],
    marginBottom: [49.5]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  h5 {
    ${PHeaderBps};
    text-align: left !important;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
  }
  p {
    ${PHeaderBps};
  }
`

export const content = css`
  display: flex;
  text-align: center;
  h5 {
    ${PHeaderBps};
    text-align: left !important;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
  }
  p {
    ${PHeaderBps};
    text-align: left !important;
    margin-bottom: 34px;
  }
`